'use strict';

define('vb/private/services/servicesManager',[
  'vb/private/services/endpointReference',
  'vb/private/stateManagement/router',
  'vb/private/stateManagement/application',
],
(EndpointReference, Router, Application) => {
  /**
   * this is the interface for getting endpoints for the active container (previously, Services was a singleton).
   * Also where Service Providers are registered (ultimately live in the Application's Services property).
   *
   */
  class ServicesManager {
    /**
     * Get an endpoint
     * First, check the active Page; if it has a services object, and that contains the endpoint, return that.
     * Otherwise, check if the Application has any services registered, and contains the endpoint.
     * @param endpointReference {EndpointReference}
     * @param {*} [serviceContext] - not used here (see OperationRefEndpointProvider).
     * @param {object} [serverVariables] - the serverVariables, if any.
     * @return {Promise<Endpoint>}
     * @public
     */
    static getEndpoint(endpointReference, serviceContext, serverVariables) {
      // eslint-disable-next-line no-underscore-dangle
      return ServicesManager._findEndpoint(endpointReference, serverVariables)
        .then((tuple) => tuple && tuple.endpoint);
    }

    /**
     * @typedef {Object} EndpointDefinitionInfo
     * @property path
     * @property [operationDef]
     * @property [serviceDef]
     * @property configInfo
     * @property requestInit
     */
    /**
     * return the service definition (openapi, swagger),
     * and additional information used to fetch the service
     * @param endpointReference {EndpointReference}
     * @returns {Promise<EndpointDefinitionInfo|*>}
     * @public
     */
    static getDefinitionInfo(endpointReference) {
      return ServicesManager.FindDefinition(endpointReference)
        .then((foundInfo) => (foundInfo ? {
          serviceDef: foundInfo.serviceDef,
          endpoint: foundInfo.endpoint,
          configInfo: foundInfo.configInfo,
          requestInit: foundInfo.requestInit,
        } : null));
    }

    /**
     * Used to programmatically register service definitions. These are registered at the Application level.
     * @param serviceProvider
     * @return {Promise}
     * @public
     */
    static addServiceProvider(serviceProvider) {
      return Application.getServices().addServiceProvider(serviceProvider);
    }

    /**
     * find the Service object that contains the declaration for the service ID, by getting the container's
     * array of Services objects, which are all Services objects in the container hierarchy.
     * We then search those Services objects for the Service.
     *
     * Finding the Service does not automatically mean that the referenced endpoint can be loaded/used.
     * Caller needs to check if the referenced endpoint can be accessed after the service definition is fetched.
     *
     * Services:Container :: one:one, each Services contains a map of Service
     *
     * @param {EndpointReference} endpointReference
     * @returns {Promise<Services>} resolved with Services
     */
    static findContainerServices(endpointReference) {
      // if we get a string for some reason, convert it
      if (typeof endpointReference === 'string') {
        // eslint-disable-next-line no-param-reassign
        endpointReference = new EndpointReference(endpointReference);
      }

      const pageOrFlow = Router.getCurrentPage();
      const allServices = (pageOrFlow && pageOrFlow.getAllServices()) || Application.getAllServices();

      //
      // Endpoints with fully qualified names can be found by their namespace
      //

      if (endpointReference.namespace) {
        const servicesArray = allServices.filter((s) => s.namespace === endpointReference.namespace);

        const handler = (services, er) => services.containsDeclaration(er).then((result) => (result ? services : null));

        return servicesArray.reduce(
          (promise, services) => promise.then((result) => result || handler(services, endpointReference)),
          Promise.resolve(),
        );
      }

      //
      // Endpoints without the namespace have to be found based on the calling context (i.e. container)
      //

      // These Services objects are starting points for the handler's search/traversal
      const servicesArray = allServices.filter((s) => s.namespace === endpointReference.containerNamespace);

      // Look for the endpointReference on the traversed "services" as well as its delegate services.
      // Using a set to avoid looking for the endpoint reference in the same "services".

      const set = new Set();
      const handler = (services, er) => {
        if (set.has(services)) {
          return false;
        }
        set.add(services);
        return Promise.resolve()
          .then(() => services.isEndpointReferenceCompatible(endpointReference) && services.containsDeclaration(er))
          .then((result) => (result ? services : services.searchDelegates((delegate) => handler(delegate, er))));
      };

      return servicesArray.reduce(
        (promise, services) => promise.then((result) => result || handler(services, endpointReference)),
        Promise.resolve(),
      );
    }

    /**
     * search declared service definitions for the endpoint (serviceId/operationId)
     *
     * @param endpointReference {EndpointReference}
     * @param {object} [serverVariables] - the serverVariables, if any.
     * @returns {Promise} resolved with {endpoint}
     * @private
     */
    static _findEndpoint(endpointReference, serverVariables) {
      return ServicesManager.findContainerServices(endpointReference)
        .then((services) => services && services.getEndpoint(endpointReference, serverVariables))
        .then((endpoint) => endpoint && { endpoint });
    }

    /**
     * Finds definition of EndpointReference, loading it if not already loaded.
     * It returns object with the ServiceDefinition and the Endpoint if it is found.
     *
     * @param endpointReference {EndpointReference}
     * @returns {Promise} resolved with the Swagger/OpenApi from the ServiceDefinition
     * @protected
     */
    static FindDefinition(endpointReference, checkAccessible = true) {
      let serviceDefinition;
      return Promise.resolve()
        .then(() => {
          if (endpointReference.serviceId) {
            return ServicesManager.findContainerServices(endpointReference);
          }
          // this should NEVER happen
          throw new
          Error(`An EndpointReference with no service ID was used, trying to find a service: ${endpointReference}`);
        })
        .then((services) => Promise.all([services,
          services && services.LoadService(endpointReference.serviceId, false, endpointReference)]))
        .then(([services, serviceDef]) => {
          if (serviceDef) {
            // we need to load the service to know if it accessible
            // as it can contain metadata describing the rules for accessing it
            if (checkAccessible && !services.isServiceAccessible(serviceDef, endpointReference)) {
              // we can not use this service for the endpoint
              return [];
            }

            serviceDefinition = serviceDef;
            // openApi is OpenApiObjectCommon, and its definition is simple JS Object
            return Promise.all([serviceDefinition.getDefinition(endpointReference),
              serviceDefinition.findEndpoint(endpointReference)]);
          }
          return [];
        })
        .then(([serviceDefObject, endpoint]) => {
          if (serviceDefinition) {
            return {
              service: serviceDefinition,
              serviceDef: serviceDefObject,
              requestInit: serviceDefinition.requestInit,
              endpoint,
            };
          }
          return null;
        });
    }

    /**
     * looks for the containing Services in the container hierarchy, and disposes the specific service
     * @param {string} id declared id of the service
     * @returns {Promise<Services>} resolved with Services, or rejected with text message
     */
    static disposeService(id) {
      return Promise.resolve()
        .then(() => ServicesManager.findContainerServices(id))
        .then((services) => {
          if (services) {
            services.disposeService(id);
            return services;
          }

          throw new Error(`unable to find service to dispose: ${id}`);
        });
    }
  }

  return ServicesManager;
});

