/* eslint-disable max-len */

'use strict';

define('vb/private/services/operationRefEndpoint',[
  'vb/private/log',
  'vb/private/services/servicesManager',
  'vb/private/services/uriTemplate',
  'vb/private/services/endpointReference',
], (Log, ServicesManager, UriTemplate, EndpointReference) => {
  const logger = Log.getLogger('/vb/private/services/operationRefEndpoint');
  /**
   * examples of operationRefs
   *
   * "x-links": { // (or "links")
   *   "SalesProfileTypeLookupLOV": {
   *      "operationRef" : "https://fuscdrmsmc217-fa-ext.us.oracle.com:443/crmRestApi/resources/11.12.0.0/crmBusinessUnits/describe#/paths/~1crmBusinessUnits~1{crmBusinessUnits_Id}~1child~1fndCommonSetIdLookups/get",
   *      "x-lov" : {
   *        "definedOn" : "$this#/RetireReasonCode",
   *        "valueField" : "LookupCode",
   *        "displayFields" : [ "Meaning" ]
   *       },
   *       "parameters" : {
   *         "finder" : "CommonSetIdLOVFinder%3BpLookupType%3DMKL_RETIRE_REASON_CD_SETID%2CpEffectiveDate%3D2020-03-19%2CpEnabledFlag%3DY%2CpReferenceGroupName%3DMKL_RETIRE_REASON_CD_SETID"
   *       }
   *
   * ************
   *
   * "operationRef" : "https://fuscdrmsmc217-fa-ext.us.oracle.com:443/fscmRestApi/resources/11.13.18.05/standardLookupsLOV/describe#/paths/~1standardLookupsLOV/get",
   * "x-lov" : {
   *  "definedOn" : "$this#/RecordSet",
   *    "valueField" : "LookupCode",
   *    "displayFields" : [ "Meaning" ]
   *   },
   *  "parameters" : {
   *     "finder" : "LookupTypeFinder%3BLookupType%3DORA_HZ_RESOURCE_SEARCH_REC_SET"
   *
   * *************
   *
   * "operationRef" : "#/paths/~1resources~1{resources_Id}/get",
   * "parameters" : {
   *   "resources_Id" : "$request.path.resources_Id"
   * }
   */

  /**
   * This class implements the Endpoint interface.
   * BUT Endpoint is a concrete class, and contains a lot of functionality that isn't needed here,
   * so this does not extend Endpoint.
   *
   * @todo: make Endpoint an interface, and extend it for the two concrete classes.
   */
  class OperationRefEndpoint {
    /**
     * Processes everything after the first '#', and returns operation path and method per
     * https://github.com/OAI/OpenAPI-Specification/blob/main/versions/3.1.0.md#operationref-examples
     * @param {string} operationRef example: '#/paths/~12.0~1repositories~1{username}/get'
     * @returns {Object}
     */
    static processOperationRef(operationRef = '#') {
      const parts = operationRef.split('#');
      const pathParts = (parts[1] || '').split('/');

      const resolved = {
        openapi: parts[0], // part in front of the '#' is location of the openApi document
        path: '',
        method: '',
      };

      if (pathParts.length === 4) {
        resolved.path = (pathParts[2] || '').replace(/~1/g, '/');
        resolved.method = pathParts[3] || 'GET';
      }

      return resolved;
    }

    /**
     * The OperationRefEndpoint may or may not have a parent Service, depending on whether the URL could be mapped.
     *
     * @param {string|null} serviceId
     * @param {string} url Resolved data fetch URL
     * @param {string} operationRef Operation reference used to resolve the data fetch URL
     * @param {Container|ServiceContext} [serviceContext] - typically the container which defines the scope in which the endpoint is being invoked
     */
    constructor(serviceId, url, operationRef, serviceContext) {
      this.url = url;

      // deconstructed operationRef
      const resolvedRef = OperationRefEndpoint.processOperationRef(operationRef);

      if (!resolvedRef.method) {
        logger.warn('Unable to determine HTTP method from "operationRef", using "GET"', operationRef);
      }
      this.method = resolvedRef.method || 'GET';

      // parameter replacement support
      this.uriTemplate = new UriTemplate(url);

      // This is only place EndpointReference is created with operation ref options
      // @todo: we should be able to create this from the url & operationRef
      this.endpointReference = new EndpointReference({ serviceId, url, operationRef: (resolvedRef.path || operationRef) }, serviceContext);

      this.service = null;
      this.endpoint = null;
    }

    /**
     * Returns a promise of initialized endpoint reference.
     * Initialization includes finding relevant service, and if it exists waiting for it to be loaded.
     * We also try to find endpoint of the referenced operation, and use it to get metadata and transforms.
     * @returns {Promise<any>}
     */
    init() {
      if (!this.servicePromise) {
        this.servicePromise = Promise.resolve()
          .then(() => {
            if (this.endpointReference.serviceId) {
              // Backward compatibility: disable service access check as we may be mapping to
              //  a wrong service which has not been exposed to extensions.
              //
              // For example: when an LOV maps to any of the services from a RAMP backend
              //  the service will have RAMP transform configured. That is good enough of the
              //  URL mapping as we will end up running correct transform. RAMP transforms do not
              //  need information about endpoint metadata, so it is OK not to have it resolved.
              return ServicesManager.FindDefinition(this.endpointReference, false)
                .then((def) => {
                  this.service = def ? def.service : null;
                  return this.service ? this.service.load() : null;
                })
                .then(() => {
                  if (this.service) {
                    this.endpoint = this.service.findEndpoint(this.endpointReference);
                  }
                  return this.endpoint && this.endpoint.load();
                })
                .catch((err) => {
                  // report error loading the service
                  logger.warn('Error while loading parts of the endpoint: ', this.url, err);
                });
            }
            this.service = null;
            return null; // for eslint, not used.
          })
          .then(() => this); // return the Endpoint
      }
      return this.servicePromise;
    }

    // {
    //   "url": "http://localhost:1988/webApps/ifixitfaster/api/incidents?technician=hcr",
    //   "method": "GET",
    //   "headers": {
    //     "vb-info-extension": "{\"headers\":{},\"baseUrl\":\"\",\"serviceName\":\"incidents\"}",
    //     "vb-allow-anonymous-access": true
    //   },
    //   "requestContentTypes": [],
    //   "responseContentTypes": []
    // }"

    /**
     * @typedef Config
     * @property {string} url
     * @property {string} [urlSuffix]
     * @property {string|Object} method
     * @property {Object} headers
     * @property {string[]} requestContentTypes
     * @property {string[]} responseContentTypes
     */
    /**
     * gets the url, method, and headers defined in the endpoint.
     *
     * @param {Object} parameters parameter values
     * @param {Object} [parameters.path]
     * @param {Object} [parameters.query]
     * @param {Object} [parameters.serverVariables]
     * @param {Object} [parameters.any]
     * @param options {Object} optional. possible properties:
     *  - ignoreMissingParams: {boolean} if true, URL can have unreplaced templates.
     *                         otherwise, rejects when params are missing
     * @returns {Promise<Config>}
     * @private
     */
    getConfig(parameters = {}, options = {}) {
      return this.init()
        .then(() => {
          // simple parameter support, for path and query params
          const flattenedParams = Object.assign({},
            parameters.any || {},
            parameters.path || {},
            parameters.query || {});
          const url = this.uriTemplate.replace(flattenedParams, options.ignoreMissingParams);

          return {
            url,
            method: this.method,
            headers: {},
            requestContentTypes: [],
            responseContentTypes: [],
          };
        });
    }

    // passed to transform as config.endpointDefinition
    // {
    //   "name": "getIncidents",
    //   "serviceId": "incidents",
    //   "method": "GET",
    //   "description": "",
    //   "url": "http://localhost:1988/webApps/ifixitfaster/api/incidents",
    //   "requestContentTypes": [],
    //   "responseContentTypes": [],
    //   "parameters": {
    //     "query": {
    //       "technician": {
    //         "defaultValue": "hcr",
    //         "name": "technician",
    //         "in": "query",
    //         "x-vb-defaultValue": "hcr"
    //       }
    //     }
    //   },
    //   "headers": {},
    //   "staticQueryParameters": {}
    // }"
    async getMetadata(expended) {
      return this.endpoint ? this.endpoint.getMetadata(expended) : {};
    }

    getRequestTransforms() {
      if (this.endpoint) {
        return this.endpoint.getRequestTransforms();
      }
      return this.service && this.service.getRequestTransforms ? this.service.getRequestTransforms() : {};
    }

    getResponseTransforms() {
      if (this.endpoint) {
        return this.endpoint.getResponseTransforms();
      }
      return this.service && this.service.getResponseTransforms ? this.service.getResponseTransforms() : {};
    }

    getMetadataTransforms() {
      if (this.endpoint) {
        return this.endpoint.getMetadataTransforms();
      }
      return this.service && this.service.getMetadataTransforms ? this.service.getMetadataTransforms() : {};
    }
  }

  return OperationRefEndpoint;
});

